import { NoSsr } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { m } from 'framer-motion';
import { useCallback } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// locales
import { allLangs, LanguageValue, useTranslate } from 'src/locales';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { onChangeLang, currentLang } = useTranslate();

  const popover = usePopover();
  const renderFallback = <Box component="span" />;

  const handleChangeLang = useCallback(
    (newLang: LanguageValue) => {
      onChangeLang(newLang);
      popover.onClose();
    },
    [onChangeLang, popover]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {/* <Iconify icon={currentLang.countryCode} /> */}
      </IconButton>
      <NoSsr fallback={renderFallback}>
        <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value as LanguageValue)}
            >
              <Iconify icon="" sx={{ borderRadius: 0.65, width: 28 }} />

              {option.label}
            </MenuItem>
          ))}
        </CustomPopover>
      </NoSsr>
    </>
  );
}
