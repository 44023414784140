import { format, getTime, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Timezone } from '../types/timezone';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export const formatStr = {
  dateTime: 'DD MMM YYYY h:mm a', // 17 Apr 2022 12:00 am
  date: 'DD MMM YYYY', // 17 Apr 2022
  time: 'h:mm a', // 12:00 am
  split: {
    dateTime: 'DD/MM/YYYY h:mm a', // 17/04/2022 12:00 am
    date: 'DD/MM/YYYY', // 17/04/2022
  },
  paramCase: {
    dateTime: 'DD-MM-YYYY h:mm a', // 17-04-2022 12:00 am
    date: 'DD-MM-YYYY', // 17-04-2022
  },
};

export function fEventStartDate(
  startDate: string | Date,
  timezone: Timezone = Timezone['America/Montreal']
): string {
  const startInLocal = convertUtcToLocalDate(startDate, timezone);

  let fmt = 'dd LLL';
  const currentYear = new Date().getFullYear();
  const startYear = startInLocal.getFullYear();

  if (currentYear !== startYear) {
    fmt += ' yyyy';
  }

  const formattedStart = format(startInLocal, fmt);

  return formattedStart;
}

export function fEventDate(
  startDate: string | Date,
  endDate?: string | Date,
  timezone: Timezone = Timezone['America/Montreal']
): string {
  const startInLocal = convertUtcToLocalDate(startDate, timezone);
  let endInLocal: Date | undefined;
  if (endDate) {
    endInLocal = convertUtcToLocalDate(endDate, timezone);
  }

  let fmt = 'EEE d LLL, HH:mm';
  const currentYear = new Date().getFullYear();
  const startYear = startInLocal.getFullYear();
  const endYear = endInLocal ? endInLocal.getFullYear() : undefined;

  if (currentYear !== startYear) {
    fmt += ' yyyy';
  }

  const formattedStart = format(startInLocal, fmt);
  let formattedEnd = '';

  if (endInLocal) {
    const isSameDay =
      startInLocal.getDate() === endInLocal.getDate() &&
      startInLocal.getMonth() === endInLocal.getMonth() &&
      startInLocal.getFullYear() === endInLocal.getFullYear();

    if (isSameDay) {
      formattedEnd = format(endInLocal, 'HH:mm');
      return `${formattedStart} - ${formattedEnd}`;
    }
    let endFmt = 'EEE d LLL, HH:mm';
    if (endYear && currentYear !== endYear) {
      endFmt += ' yyyy';
    }
    formattedEnd = format(endInLocal, endFmt);
    return `${formattedStart} - ${formattedEnd}`;
  }

  return formattedStart; // If no end date, return the formatted start date only
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDate(date: Date, tz: Timezone = Timezone['America/Montreal']): string {
  let fmt = 'dd LLL';
  const dateInUTC = convertUtcToLocalDate(date, tz);
  const currentYear = new Date().getFullYear();
  const dateYear = dateInUTC.getFullYear();

  if (currentYear !== dateYear) {
    fmt += ' yyyy';
  }

  return format(dateInUTC, fmt);
}

export function formatEventStartTime(date: Date): string {
  return format(new Date(date), 'EEEE, p');
}

export function convertUtcToLocalDate(date: string | Date, organizerTimezone: Timezone): Date {
  const utcDate = new Date(date);
  const dateInOrganizerTz = utcToZonedTime(utcDate, organizerTimezone);
  return dateInOrganizerTz; // Return the local time, do not convert back to UTC.
}

export function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function calculateCartTimeLeft(createdAt?: Date) {
  if (!createdAt || createdAt === null) {
    // 15 minutes
    return 15 * 60;
  }
  const createdDate = new Date(createdAt).getTime();
  const currentDate = new Date().getTime();
  const fifteenMinutes = 15 * 60 * 1000;
  const timePassed = currentDate - createdDate;
  const timeLeft = fifteenMinutes - timePassed;

  // Convert to seconds and ensure it's not negative
  return Math.max(0, Math.floor(timeLeft / 1000));
}
